'use strict';

var config = require('./config.js');
var logger = require('./logger.js');

var self = module.exports = {
	pushToDataLayer: function (obj) {
		if (obj) {
			logger.info('GTM data layer push', obj);

			if (typeof bauerDataLayer !== 'undefined') {
				bauerDataLayer.push(obj);
			} else {
				window.bauerDataLayer = [obj];
			}
		}
	},

	trackEvent: function (category, action, label, value) {
		if (category && action) {
			var event = {
				event: 'GA_Event',
				eventCategory: category,
				eventAction: action,
				eventLabel: label || config.data.page.templateName,
				eventValue: value
			};
			self.pushToDataLayer(event);
		}
	},

	trackPageview: function (url, title) {
		var pageView = {
			event: 'GA_VirtualPageview',
			virtualPageUrl: url,
			virtualPageTitle: title
		};
		self.pushToDataLayer(pageView);
	},

	trackIpsosIrisPageView: function () {
		// window.dm is defined in the Ipsos Iris script, see _IpsosIris partial view
		var ii = window.dm;
		if (ii) {
			ii.AjaxEvent('pageview', null, 'todaysgolfer');
		}
	},

	addDataLayerValues: function (values) {
		self.pushToDataLayer({
			event: 'Data Layer Loaded',
			...values
		});
	}
};