'use strict';

/*globals Waypoint */
require('waypoints');

var $			  	  = require('jquery');
var heightWatcher 	  = require('../watchers/height-watcher.js');
var layout            = require('./layout.js');
var logger			  = require('../utils/logger.js');

var classNames = {
	backToTop: 'back-to-top',
	desktopSidebar: 'desktop-sidebar__sticky-wrapper',
	inner: 'inner',
	siteContent: 'site-content',
	siteFooter: 'site-footer',
	stickyContainer: 'sticky-container',
	topAd: 'top-ad',
	topAdContainer: 'top-ad-container'
};

var modifierNames = {
	stuck: 'stuck'
};

var waypointInstances = {};
var stickyMpuAdWaypoints = [];

// Creates a waypoint that will cause the element to stick if scrolled past
function createStickyInstance(baseClassName, $stickyElement, stickyOffset, $bottomElement, bottomOffset) {
	if (!$stickyElement || $stickyElement.length === 0) {
		return;
	}

	stickyOffset = stickyOffset || 0;
	bottomOffset = bottomOffset || 0;

	$stickyElement.parent().css('height', $stickyElement.parent().height()); // Preserve container size when element position is set to fixed

	var stickyInstance = new Waypoint({
		element: $stickyElement,
		offset: stickyOffset,
		handler: function (direction) {
				if (direction === 'up') {
					$stickyElement.removeBemClass(baseClassName, null, 'stuck');
				} else {
					$stickyElement.addBemClass(baseClassName, null, 'stuck');
				}
		}
	});

	waypointInstances[baseClassName + '--sticky-element'] = stickyInstance;

	if ($bottomElement && $bottomElement.length > 0) {
		var stickyElementBottom = stickyOffset + $stickyElement.outerHeight(true);

		var bottomInstance = new Waypoint({
			element: $bottomElement,
			offset: stickyElementBottom + bottomOffset,
			handler: function (direction) {
				if (direction === 'up') {
					$stickyElement.removeBemClass(baseClassName, null, 'stuck-bottom');
				} else {
					$stickyElement.addBemClass(baseClassName, null, 'stuck-bottom');
				}
			}
		});

		waypointInstances[baseClassName + '--bottom-element'] = bottomInstance;
	}
}

// Removes the sticky waypoint attached to an element
function removeStickyInstance(baseClassName, $stickyElement) {
	if (baseClassName && $stickyElement.length > 0) {
		var stickyInstance = waypointInstances[baseClassName + '--sticky-element'];
		var bottomInstance = waypointInstances[baseClassName + '--bottom-element'];

		if (stickyInstance) {
			stickyInstance.destroy();
		}

		if (bottomInstance) {
			bottomInstance.destroy();
		}

		$stickyElement.removeBemClass(baseClassName, null, 'stuck');
		$stickyElement.removeBemClass(baseClassName, null, 'stuck-bottom');
		$stickyElement.parent().css('height', 'auto');
	}
}

// 30 Ad slots added on each page, function calculates/unhides max number per page
function showMaxDesktopSideBarAdSlots() {
	var siteContentInnerHeight = $.findByBem(classNames.siteContent, classNames.inner).height();
	var mpuHeightSum = 1200;
	var maxAdSlots = Math.floor( siteContentInnerHeight / mpuHeightSum);
	$('.sidebarAdBlock').slice(0, maxAdSlots).each(function (index) {
		this.style.display = "block";
		if (index === maxAdSlots - 1) {
			$(this).find('a').css("display", "inline");
		}
	});
}

// Sticks the mpu to the top of the screen when scrolling down
function stickMpu(element) {
    element.style.position='fixed';
    element.style.top = '16px'; // padding top of ad container
    element.classList.add("stuck");     
}

// Unsticks the mpu at bottom of its sidebar ad block when scrolling down
function unstickMpuBottom(){
	var stuckElement =  $('.stuck');
	stuckElement.removeClass("stuck");
	stuckElement.css({"position": "relative", "top": "600px"}); // height of add wrapper - (ad height +  padding-bottom)
}
 
// Unsticks the mpu at top of its sidebar ad block when scrolling up
function unstickMpuTop(){
	var stuckElement =  $('.stuck');
	stuckElement.removeClass("stuck");
	stuckElement.css({"position": "relative", "top": "16px"}); 
}

function setStickyMpuAds() {
	var initWaypoints = function () {
		var screenSize = layout.getScreenSize();
		if (screenSize === 'XL' || screenSize === 'XXL') {
			
			stickyMpuAdWaypoints.forEach(function (waypoint) {
				waypoint.destroy();
			});

			stickyMpuAdWaypoints.length = 0;

			$('.sidebarAdBlock:visible').each(function () {
				var adBlock =  $(this).get(0);
				var adContainer = adBlock.querySelector('.sidebarAdBlock__inner');

				stickyMpuAdWaypoints.push (
					// Scrol down triggers for setting sticky component
					new Waypoint({
						element: adBlock,
						handler: function (direction) {
							if(direction==='down'){
								stickMpu(adContainer);
							}  
						},
					}),
				
					//scroll up trigger for unsetting sticky component
					new Waypoint({
						element:adBlock,
						handler: function (direction) {
							if(direction==='up'){
								stickMpu(adContainer);
							}               
						}, 
						offset:-600 // The ad height (150) + padding top of container (1)
					}),

					// Scroll down triggers for unsetting sticky component
					new Waypoint({
						element: adBlock,
						handler: function (direction) {
							if(direction==='down'){
								unstickMpuBottom();
							}	 
						},
						offset: 648 // Hight of ad (150) + Wrapper padding-bottom (1) + wrapper padding-top(1px) +  margin bottom (1px)
					}),

					//Scroll up triger for setting sticky component
					new Waypoint({
						element: adBlock,
						handler: function (direction) {
							if(direction==='up'){
								unstickMpuTop();
							}	 
						},         
					})
				);
			});
		}
	};	

	$(window).scroll(function(){
		if ($(window).scrollTop() === 0) {
			initWaypoints();
		}
	});

	initWaypoints();	
}

function setStickySidebar() {
	var screenSize = layout.getScreenSize();
	var desktopSidebarInnerClassName = $.getBemClass(classNames.desktopSidebar, classNames.inner);
	var $desktopSidebar = $('.' + desktopSidebarInnerClassName);
	var $siteFooter = $.findByBem(classNames.siteFooter);

	removeStickyInstance(desktopSidebarInnerClassName, $desktopSidebar);

	if (screenSize === 'XL' || screenSize === 'XXL') {
		createStickyInstance(desktopSidebarInnerClassName, $desktopSidebar, layout.getContentOffset() + 16, $siteFooter);
	}
}

function setStickyLeaderboard() {
	var stickyContainerClassName = $.getBemClass(classNames.topAdContainer, classNames.stickyContainer);
	var $stickyContainer = $('.' + stickyContainerClassName);

	var stickyInstance = new Waypoint({
		element: $stickyContainer,
		handler: function (direction) {
			if (direction==='down'){
				$stickyContainer.addBemClass(classNames.topAdContainer, classNames.stickyContainer, modifierNames.stuck);
			} else {
				$stickyContainer.removeBemClass(classNames.topAdContainer, classNames.stickyContainer, modifierNames.stuck);
			}
		},
	});

	waypointInstances[stickyContainerClassName + '--sticky-element'] = stickyInstance;

	logger.debug('GAM: Leaderboard stuck');
}

function unsetStickyLeaderboard() {
	var stickyContainerClassName = $.getBemClass(classNames.topAdContainer, classNames.stickyContainer);
	var stickyInstance = waypointInstances[stickyContainerClassName + '--sticky-element'];

	if (stickyInstance) {
		stickyInstance.destroy();
	}

	var $stickyContainer = $('.' + stickyContainerClassName);
	$stickyContainer.removeBemClass(classNames.topAdContainer, classNames.stickyContainer, modifierNames.stuck);
}

module.exports = {
	init: function () {
		showMaxDesktopSideBarAdSlots();
		if (typeof Waypoint !== 'undefined') {
			setStickySidebar();
			setStickyMpuAds();

			heightWatcher.onChange(function () {
				setStickySidebar();
				setStickyMpuAds();
			});

			if ($(window).scrollTop() > 0) {
				// recaclulate offsets after page loaded
				$(window).load(function () {
					setStickySidebar();
				});
			}

			$.findByBem(classNames.desktopSidebar, classNames.backToTop).on('click.sticky', function (e) {
				e.preventDefault();
				layout.scrollTop(0);
			});
		}
		setStickyLeaderboard();
	},

	removeStickyInstance: removeStickyInstance,
	unsetStickyLeaderboard: unsetStickyLeaderboard
};