'use strict';

var logger = require('./logger.js');

var tcfVersion = 2;

var eventNames = {
	uiShown: 'cmpuishown',
	userActionComplete: 'useractioncomplete',
	subsequentPageLoad: 'tcloaded'
};

async function onGetTCData() {
	const tcData = await new Promise((resolve, reject) => {
		window.__tcfapi('getTCData', tcfVersion, function (data, success) {
			if (success) {
				resolve(data);
			} else {
				reject(new Error('Failed to get TC Data'));
			}
		});
	});

	return tcData;
}

async function hasIABVendorConsent(vendorId) {
	try {
		// Ensure vendorId is not null, not 0, and is a valid number
		const validVendorId = Number(vendorId);
		if (vendorId === null || validVendorId === 0 || isNaN(validVendorId)) {
			logger.error('Invalid vendorId: It must be a non-null, non-zero number');
			return false;  // Return false for invalid vendorId
		}

		// Fetch TC Data and check consent
		const tcData = await onGetTCData();

		if (tcData && tcData.vendor && tcData.vendor.consents) {
			const vendorConsents = tcData.vendor.consents;

			if (vendorConsents[vendorId]) {
				logger.info(`Vendor ID: ${vendorId} has consent.`);
				return true;
			} else {
				logger.info(`Vendor ID: ${vendorId} does NOT have consent.`);
				return false;
			}
		} else {
			logger.error('TC Data is missing vendor or consents information');
			return false;
		}

	} catch (err) {
		logger.error('An error occurred:', err);
		return false;
	}
}

var vendorConsents = {

	eventNames:eventNames,
	hasIABVendorConsent,
	addTcfApiHandler: function (eventStatus, callback) {
		window.__tcfapi('addEventListener', tcfVersion, function (tcData, success) {
			if (!success) {
				logger.warn('Failed to add tcf api handler');
				return;
			}

			if (tcData.eventStatus === eventNames.uiShown && eventStatus === eventNames.uiShown) {
				callback();
			}

			if (tcData.eventStatus === eventNames.userActionComplete && eventStatus === eventNames.userActionComplete) {
				callback();
			}

			if (tcData.eventStatus === eventNames.subsequentPageLoad && eventStatus === eventNames.subsequentPageLoad) {
				window.__tcfapi('removeEventListener', tcfVersion, function (success) {
					if (success) {
						callback();
					}
				}, tcData.listenerId);
			}
		});
	},

	onRetrieveVendorAndPurposeConsents: function (successCallback, failureCallback) {
		var hasBauerConsent = false;
		var hasStoreAndAccessInfoConsent = false;
		var hasSelectPersonalisedAds = false;
		var hasCreatePersonalisedAdsProfile = false;

		window.__tcfapi('getCustomVendorConsents', tcfVersion, function (vendorConsents, success) {
			if (!success) {
				logger.warn('Failed to add bauer consent handler');
				return;
			}
			vendorConsents.consentedVendors.forEach(function (vendor) {
				if(vendor.name === 'Bauer Media Group') {
					hasBauerConsent = true;
				}
			});

			vendorConsents.consentedPurposes.forEach(function (purpose) {
				if (purpose.name === 'Store and/or access information on a device') {
					hasStoreAndAccessInfoConsent = true;
				}

				if (purpose.name === 'Use profiles to select personalised advertising') {
					hasSelectPersonalisedAds = true;
				}

				if (purpose.name === 'Create profiles for personalised advertising') {
					hasCreatePersonalisedAdsProfile = true;
				}
			});

			if (hasBauerConsent && hasStoreAndAccessInfoConsent && hasSelectPersonalisedAds && hasCreatePersonalisedAdsProfile) {
				successCallback();
			} else {
				failureCallback();
			}
		});
	}
};

module.exports = vendorConsents;