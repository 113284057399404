'use strict';

var $ = require('jquery');

var heightWatcherCallbacks = [];

var classNames = {
	siteRoot: 'site-root'
};

module.exports = {
	onChange: function (func) {
		heightWatcherCallbacks.push(func);
	},

	start: function () {
		var self = this;
		self.watchElementHeight($.findByBem(classNames.siteRoot), 500, function () {
			self.trigger();
		});
	},

	trigger: function () {
		var index, callback;
		for (index = 0; index < heightWatcherCallbacks.length; index++) {
			callback = heightWatcherCallbacks[index];
			if ($.isFunction(callback)) {
				callback();
			}
		}
	},

	watchElementHeight: function ($element, interval, callback) {
		if ($element.length > 0) {
			var previousHeight = $element.height();
			(function run() {
				var newHeight = $element.height();
				if (newHeight !== previousHeight) {
					callback();
				}
				previousHeight = newHeight;
				window.setTimeout(run, interval);
			})();
		}
	}
};