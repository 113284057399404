'use strict';

var $	   = require('jquery');
var layout = require('./layout.js');

var classNames = {
	moreMenu: 'more-menu',
	primaryNavHorizontal: 'primary-nav-horizontal',
	siteHeaderHorizontal: 'site-header-horizontal'
};

var initSubMenus = function (block) {
	var element = 'menu__link';
	$.findByBem(block, element, 'has-sub-menu').on('click.menu', function (e) {
		var isActive = $(this).parent().hasBemClass(block, element, 'active');
		if ($(this).prop('href') === '#' || !isActive) {
			e.preventDefault();
			if (isActive) {
				$(this).parent().trigger('mouseleave');
			} else {
				$(this).parent().trigger('mouseenter').siblings().trigger('mouseleave');
			}
		}
	}).parent().on('mouseenter.menu', function () {
		$(this).addBemClass(block, element, 'active').childrenByBem(block, 'sub-menu').show().children('li').css('min-width', $(this).width());
	}).on('mouseleave.menu', function () {
		$(this).removeBemClass(block, element, 'active').childrenByBem(block, 'sub-menu').hide();
	});
};

var initMoreMenu = function () {
	var screenSize = layout.getScreenSize();
	if (screenSize === 'L' || screenSize === 'XL' || screenSize === 'XXL') {
		var width = 0;
		var $horizontalNav = $.findByBem(classNames.primaryNavHorizontal);
		var menuTop;
		if ($horizontalNav.findByBem(classNames.primaryNavHorizontal, 'menu').length > 0) {
			menuTop = $horizontalNav.findByBem(classNames.primaryNavHorizontal, 'menu').position().top;
		} else {
			menuTop = 0;
		}
		var $moreLink = $horizontalNav.findByBem(classNames.primaryNavHorizontal, 'more');
		var $moreMenu = $.findByBem(classNames.moreMenu);
		var $moreMenuList = $moreMenu.findByBem(classNames.moreMenu, 'list');
		var overflowDropdowns = [];
		var overflowSingles = [];

		$moreLink.off('click.menu').on('click.menu', function (e) {
			e.preventDefault();
			if ($moreMenu.is(':visible')) {
				$moreMenu.slideUp('fast');
			} else {
				$moreMenu.slideDown('fast');
			}
		});

		$horizontalNav.findByBem(classNames.primaryNavHorizontal, 'menu__item').each(function () {
			if ($(this).position().top > menuTop && window.getComputedStyle(this, null).display !== 'none') {
				var $item = $(this).clone();
				if ($item.children('a').attr('data-text-expanded')) {
					$item.children('a').html($item.children('a').attr('data-text-expanded'));
				}
				$item.removeAttr('class id style').addClass('more-menu__item')
					.children('a').removeAttr('class id style data-text-expanded').addClass('more-menu__link more-menu__link--heading');
				if ($item.children('ul').length > 0) {
					$item.children('ul').removeAttr('class id style').addClass('more-menu__sub-list')
						.children('li').removeAttr('class id style').addClass('more-menu__sub-list__item')
						.children('a').removeAttr('class id style').addClass('more-menu__sub-list__link');
					overflowDropdowns.push($item);
				} else {
					overflowSingles.push($item);
				}
			} else {
				width += $(this).width();
			}
		});

		if (overflowDropdowns.length > 0) {
			$moreMenuList.html(overflowDropdowns);
			if (overflowSingles.length > 0) {
				if (overflowDropdowns.length > 1) {
					var $list = $('<li class="more-menu__item" />').appendTo($moreMenuList);
					$(overflowSingles).each(function () {
						$list.append($(this).children('a'));
					});
				} else {
					$moreMenuList.append(overflowSingles);
				}
			}
		} else if (overflowSingles.length > 0) {
			$moreMenuList.html(overflowSingles);
		}

		if (overflowDropdowns.length > 0 || overflowSingles.length > 0) {
			$moreLink.show();
		} else {
			$moreLink.hide();
			$moreMenu.hide();
		}
	}
};

module.exports = {

	init: function () {
		initSubMenus(classNames.primaryNavHorizontal);

		initMoreMenu();

		$(window).on('resize.menu', function () {
			initMoreMenu();
		});
	}

};