'use strict';

var $ = require('jquery');

var classNames = {
	screenSizeDetector: 'screen-size-detector',
	siteHeader: 'site-header'
};

var self = module.exports = {
	getContentOffset: function () {
		var result = 0, screenSize = this.getScreenSize();
		if (screenSize === 'L' || screenSize === 'XL') {
			var $siteHeader = $.findByBem(classNames.siteHeader);
			if ($siteHeader.length > 0) {
				result = $siteHeader.outerHeight();
			}
		}
		return result;
	},

	getScreenSize: function () {
		return $.findByBem(classNames.screenSizeDetector).css('font-family').toUpperCase();
	},
	
	scrollTop: function (offset, duration) {
		duration = duration || 200;

		if (offset > 0 && this.getScreenSize() === 'XL') {
			var $siteHeader = $.findByBem(classNames.siteHeader);
			if ($siteHeader.length > 0 && offset > $siteHeader.height()) {
				offset = offset - $siteHeader.height();
			}
		}

		if (duration > 0) {
			$('html, body').animate({
				scrollTop: offset
			}, 200);
		} else {
			$('html, body').css('scrollTop', offset);
		}
	},

	isMobile: function () {
		var screenSize = self.getScreenSize();
		return screenSize === 'S' || screenSize === 'M';
	}
};