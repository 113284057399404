'use strict';

var vendorConsents = require('./vendor-consent.js');
var permutive = window.permutive;

function consentGranted () {
	permutive.consent({ 'opt_in': true, 'token': 'CONSENT_CAPTURED' });
}

function consentRefused () {
	console.log('Permutive consent has not been granted - no data will be collected');
	permutive.consent({ 'opt_in': false });
}


permutive.init = function() {
	vendorConsents.addTcfApiHandler(vendorConsents.eventNames.userActionComplete, function () {
		vendorConsents.onRetrieveVendorAndPurposeConsents(consentGranted, consentRefused);
	});

	vendorConsents.addTcfApiHandler(vendorConsents.eventNames.subsequentPageLoad, function () {
		vendorConsents.onRetrieveVendorAndPurposeConsents(consentGranted, consentRefused);
	});
};

module.exports = permutive;