'use strict';

var $ = require('jquery');

function initTakeover(settings) {
	if ($(window).width() >= 1100) {
		if (settings && settings.link) {
			var $body = $('body');
			if ($body.hasClass('takeover')) {

				var style = {};

				if (settings.backgroundColor) {
					style.backgroundColor = settings.backgroundColor;
				}

				var leftBackgroundImage;
				var leftBackgroundPosition;
				var rightBackgroundImage;
				var rightBackgroundPosition;

				if (settings.leftGutterImage) {
					leftBackgroundImage = "url('" + settings.leftGutterImage + "')";
					leftBackgroundPosition = "left 0 top 0";
				}

				if (settings.rightGutterImage) {
					rightBackgroundImage = "url('" + settings.rightGutterImage + "')";
					rightBackgroundPosition = "right 0 top 0";
				}

				var backgroundImage = '';
				var backgroundPosition = '';
				var backgroundSize = '';

				if (leftBackgroundImage) {
					backgroundImage += leftBackgroundImage + ", ";
					backgroundPosition += leftBackgroundPosition + ", ";
					backgroundSize += "auto, ";
				}

				if (rightBackgroundImage) {
					backgroundImage += rightBackgroundImage + ", ";
					backgroundPosition += rightBackgroundPosition + ", ";
					backgroundSize += "auto, ";
				}

				backgroundImage += "url('" + settings.mainImage + "')";

				style.backgroundImage = backgroundImage;

				if (settings.backgroundFixed) {
					style.backgroundAttachment = "fixed";
					$body.addClass('takeover-fixed');
				}
				
				style.backgroundRepeat = "no-repeat, no-repeat, no-repeat";
				backgroundPosition += "center top 0";
				style.backgroundPosition = backgroundPosition;
				
				var paddingTop = settings.topAdSpaceHeight + "px";
				var paddingRight = settings.rightGutterWidth + "px";
				var paddingLeft = settings.leftGutterWidth + "px";

				style.padding = paddingTop + " " + paddingRight + " 0 " + paddingLeft;

				if (settings.mainImageBackgroundSize) {
					style.backgroundSize = backgroundSize + settings.mainImageBackgroundSize;
				} else {
					style.backgroundSize = backgroundSize + "auto";
				}

				if ($(window).width() > 1500) {
					style.backgroundSize = backgroundSize + "cover";
				}

				style.cursor = "pointer";

				$body.css(style);

				$body.find('.site-root').css({
					"cursor" : "default"
				});

				$body.off('click.takeover').on('click.takeover', function (e) {
					if (e.target.tagName.toLowerCase() === 'body') {
						e.stopPropagation();
						var win = window.open(settings.link);
						win.focus();
					}
				});
			}
		}
	}
}

module.exports = {
	initTakeover: initTakeover
};