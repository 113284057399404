'use strict';

var $ = require('jquery');
var analytics = require('./analytics');
var config = require('./config');

function getValueOrNotSet(value) {
	return value ? value : 'NotSet';
}

function isExternalLink(href) {
	// Extract the domain of the current page to compare against
	try {
		const currentDomain = new URL(window.location.href).hostname;
		const linkDomain = new URL(href, window.location.href).hostname;

		return currentDomain !== linkDomain;
	} catch (error) {
		console.error(`Invalid URL: ${href}`, error);
		return false;
	}
}

function buildQueryParams(href, trackingParams) {
	if (href.startsWith('https://www.amazon.co.uk') || href.startsWith('https://www.amazon.com')) {
		return {
			'asc_source': 'paidtraffic',
			'asc_campaign': `${trackingParams.trackingIdName},${trackingParams.trackingId}`,
			'asc_refurl': window.location.href
		};
	} else if (href.startsWith('https://redirect.viglink.com')) {
		return {
			'cuid': `${getValueOrNotSet(trackingParams.utmMedium)}|${getValueOrNotSet(trackingParams.utmSource)}|${trackingParams.trackingIdName},${trackingParams.trackingId}`
		};
	} else {
		return {
			'xcust': `${getValueOrNotSet(trackingParams.utmMedium)}|${getValueOrNotSet(trackingParams.utmSource)}|${trackingParams.trackingIdName},${trackingParams.trackingId}`
		};
	}
}

function updateLinkParams($link, params) {
	const linkParams = new URLSearchParams($link[0].search);
	Object.keys(params).forEach(key => linkParams.set(key, params[key]));

	$link[0].search = linkParams.toString();
	console.info(`Updated href: ${$link.attr('href')}`);
}

function updateTrackingLinks(trackingParams) {
	const excludedDomains = [
		'http://images.golf.bauermedia.group',
		'https://todaysgolfer-images.dev.bauersecure.com',
		'https://todaysgolfer-images.stg.bauersecure.com',
		'https://todaysgolfer-images.bauersecure.com',
		'https://www.todays-golfer.com',
		'mailto'
	];

	// Select all links that meet affiliate criteria and exclude nav/footer links
	$('a').filter(function () {

		const href = $(this).attr('href');
		const isExcluded = excludedDomains.some(prefix => href?.startsWith(prefix));

		return href && isExternalLink(href) && !isExcluded && !$(this).closest('nav, footer').length;
	}).each(function () {
		const $link = $(this);

		console.info(`Processing href: ${$link.attr('href')}`);
		updateLinkParams($link, buildQueryParams($link.attr('href'), trackingParams));
	});
}

function init() {
	const trackingParams = config.ppcTrackingParams;

	if (!trackingParams) {
		return;
	}

	updateTrackingLinks(trackingParams);

	analytics.pushToDataLayer({ event: 'AffiliateLinksUpdated' });
}

module.exports = {
	init: init
};