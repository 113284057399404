'use strict';

var config = require('./config.js');

module.exports = {
	debug: function (message) {
		if (message && config.debugMode) {
			console.log(message);
		}
	},

	error: function (message, debugMessage) {
		console.error(message, config.debugMode ? debugMessage : null);
	},

	info: function (message, debugMessage) {
		console.info(message, config.debugMode ? debugMessage : null);
	},

	warn: function (message, debugMessage) {
		console.warn(message, config.isDebugMode ? debugMessage : null);
	},

	secondsSincePageStart: function() {
		return (window.pageStart ? (Date.now() - window.pageStart) / 1000 : 0) + 's';
	}
};