'use strict';

var vendorConsents = require('./vendor-consent.js');
var logger = require('./logger.js');

var covatic = window.covaticBrowserSDK;
var mediaAssetDuration = 3600; // Set actual duration if available
var playHeadPosition = 300;    // Set playhead position based on context

async function consentGranted() {
    var mediaConsumptionData = null;

    // Check if MediaConsumptionData exists in the window object
    if (window.MediaConsumptionData !== null && window.MediaConsumptionData !== undefined) {
        logger.info('Covatic media consumption data available.');
        mediaConsumptionData = window.MediaConsumptionData;
    }

    // Initialize the Covatic Browser SDK using the client config
    covatic.Client(mediaConsumptionData.clientConfig);

    // Notify Covatic that user consent has been granted
    covatic.notifyConsent(true);
    logger.info('Covatic notified: consent granted.');

    // Wait until the Covatic SDK is ready before proceeding
    var isSdkReady = await covatic.isReady();

    if (isSdkReady) {
        logger.info('Covatic SDK is ready.');

        // Notify Covatic of the user's subscription level (guest account in this case)
        covatic.notifySubscription('guestAccount');
        logger.info('Covatic subscription level notified.');

        // Notify Covatic of media consumption, if mediaConsumptionData is available
        if (mediaConsumptionData) {
            covatic.notifyEvent(
                new covatic.MediaConsumption(
                    '', // mediaId or assetId (empty not applicable)
                    mediaConsumptionData.mediaAssetTitle,
                    { 'cvc_description': mediaConsumptionData.cvcDescription },
                    mediaConsumptionData.mediaTag,
                    mediaConsumptionData.mediaSubsection,
                    mediaAssetDuration,  // Duration of the media
                    playHeadPosition,    // Current playback position
                    covatic.MediaConsumption.Action.commenceMedia,
                    covatic.MediaConsumption.Type.text // Media type (e.g., text)
                )
            );
            logger.info('Covatic media consumption notified.');
        } else {
            logger.warn('Covatic no media consumption data available.');
        }

        // Fetch and handle audience profiles from Covatic
        var audienceProfiles = covatic.getLinkedAudiences();
        logger.info('AudienceProfiles:', audienceProfiles);

        if (audienceProfiles && audienceProfiles.length > 0) {
            window.localStorage.setItem('_audienceProfile', audienceProfiles);
            logger.info('Covatic audience profiles stored in localStorage.');
        }
    } else {
        logger.warn('Covatic SDK is not ready.');
    }

}

function consentRefused() {
    logger.info('Covatic consent has not been granted - no data will be collected');
	// Remove the _audienceProfile if exist on local storage
	if (localStorage.getItem('_audienceProfile') !== null) {
        localStorage.removeItem('_audienceProfile');
        logger.info('Covatic audience profile has been removed from localStorage');
    }
    covatic.notifyConsent(false);
}

async function handleTcfEvent(eventName, vendorId) {
    vendorConsents.addTcfApiHandler(eventName, async () => {
        var consentGrantedResult = await vendorConsents.hasIABVendorConsent(vendorId);

        if (consentGrantedResult) {
            await consentGranted();
        } else {
            consentRefused();
        }
    });
}

module.exports = {

    init: function () {
		// Vendor ID for Covatic
        var vendorId = 1104;

        var eventsToHandle = [
            vendorConsents.eventNames.userActionComplete,
            vendorConsents.eventNames.subsequentPageLoad
        ];

        eventsToHandle.forEach(eventName => handleTcfEvent(eventName, vendorId));
    }
};