'use strict';

var $ = require('jquery');
var config = require('./config.js');
require('../../vendors/sourcepoint/bootstrap.js');

var consentLinkIds = {
	eu: '#eu-consent-ui',
	us: '#us-consent-ui'
};

function load(account_id, messaging_subdomain) {
	var messaging_library_url = 'assets/js/vendors/sourcepoint/tg-client.js';
	
	window._sp_ = window._sp_ || {};
	window._sp_.config = window._sp_.config || {};
	
	window._sp_.config.account_id = account_id;
	window._sp_.config.mms_domain = messaging_subdomain;
	
	window._sp_.config.mms_client_data_callback = function (o) {
		console.log("Sourcepoint callback: " + o);
	};
	
	window._sp_.config.mms_choice_selected_callback = function (choiceID) {
		console.log("Sourcepoint choice: " + choiceID);
	};
	
	window._sp_.mms = window._sp_.mms || {};
	window._sp_.mms.cmd = window._sp_.mms.cmd || [];
	
	window._sp_.mms.cmd.push(function(){
		window._sp_.mms.startMsg();
	});
	
	window._sp_.bootstrap(config.assetsUrl + messaging_library_url);
}

function showPrivacyManagerLink(linkId) {
	$(linkId).find('a').removeClass('hide');
}

function initPrivacyManager(gdprApplies) {
	console.log('tcData.gdprApplies: ' + gdprApplies);

	if (gdprApplies) {
		showPrivacyManagerLink(consentLinkIds.eu);
	} else {
		window.__uspapi('getUSPData', 1, function (uspData) {
			if (JSON.stringify(uspData).indexOf('---') === -1) {
				showPrivacyManagerLink(consentLinkIds.us);
			}
		});
	}
}

function conditionallySetAdBlockDetection() {
	if ($('.sourcepoint').length > 0) {
		var tg_sourcepoint_account_id = 296;
		var tg_sourcepoint_messaging_subdomain = 'sp.todays-golfer.com';
		
		load(tg_sourcepoint_account_id, tg_sourcepoint_messaging_subdomain);
	}
}

module.exports = {
	init: function() {
		conditionallySetAdBlockDetection();

		$(document).on('tcf.privacymanager', function (event, data) {
			initPrivacyManager(data.gdprApplies);
		});
	}
}; 