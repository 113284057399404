'use strict';

var $ 	   = require('jquery');
var layout = require('./layout.js');
var heightWatcher = require('../watchers/height-watcher.js');


function equalizeGridHeights() {
	$('.block-grid').each(function () {
		var $grid = $(this);
		var $items = $grid.children('li:not(.equalised)');
		var screenSize = layout.getScreenSize();
		var columns = $grid.attr('data-columns-' + screenSize);
		var innerTag;

		if ($items.children().prop('tagName') === 'A') {
			innerTag = $items.find('a').children().prop('tagName');
		} else {
			innerTag = $items.children().prop('tagName');
		}

		if (columns && !isNaN(columns)) {
			columns = parseInt(columns);
			if (columns > 1) {
				var count = 0;
				var highest = 0;
				var $item, row = [];
				$items.each(function () {
					count++;
					$item = $(this);
					row.push($item.find(innerTag).not(innerTag + " > "  + innerTag));
					$item.find(innerTag).not(innerTag + " > "  + innerTag).css('height','auto');
					highest = $item.height() > highest ? $item.height() : highest;
					if (count % columns === 0 || $items.length === count) {
						$(row).each(function () {
							$(this).css('height', highest + 'px').parent().addClass('equalised');
						});
						highest = 0;
						row = [];
					}
				});
			} else {
				$items.find(innerTag).not(innerTag + " > " + innerTag).css('height', 'auto');
			}
		}
	});
}

module.exports = {
	init: function () {
		equalizeGridHeights();

		$(window).on('resize.gridHeights', function () {
			equalizeGridHeights();
		});

		$(window).on('load.gridHeights', function () {
			equalizeGridHeights();
		});

		$('img').each(function(){
			if ($(this).parents('.grid-panel').length) {
				heightWatcher.watchElementHeight($(this), 1000, function(){
					equalizeGridHeights();
				});
			}
		});
	}
};