'use strict';

var slotSettings = {
	leaderboard1: {
		id: 'leaderboard-1',
		sizes: {
			desktop: [[728, 90], [970, 250]],
			mobile: [[320, 50], [320, 100]],
			sizeMapping: [
				[
					[1050, 0], [[970, 250], [728, 90]]
				],
				[
					[767, 0], [[728, 90]]
				],
				[
					[640, 0], [[320, 100], [320, 50]]
				],
				[
					[0, 0], [[320, 100], [320, 50]]
				]
			]
		}
	},

	mpu: {
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250], [320, 480], [300, 600], [320, 50], [320, 100]]
		}
	},

	mpuGallery: {
		id:'mpu-gallery',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250], [320, 480], [300, 600]]
		}
	},

	overlay: {
		id: 'overlay',
		sizes: {
			desktop: [[1, 1]],
			mobile: [[1, 1]]
		}
	},

	inlineFooter: {
		id: 'inline-footer',
		positionValue: 'inline-footer',
		sizes: {
			desktop: [[300, 250], [300, 600], [728, 90], [970, 250]],
			mobile: [[300, 250], [320, 480], [300, 600]],
			sizeMapping: [
				[
					[1324, 0], [[300, 250], [300, 600], [728, 90], [970, 250]]
				],
				[
					[748, 0], [[300, 250], [300, 600], [728, 90]]
				],
				[
					[488, 0], [[300, 250], [300, 600]]
				],
				[
					[0, 0], [[300, 250], [300, 600]]
				]
			]
		}
	},

	seedTag: {
		id: 'in_image',
		sizes: {
			desktop: [[1, 3]],
			mobile: [[1, 3]]
		}
	},
	
	gumGum: {
		id: 'sticky_ad_bottom',
		sizes: {
			desktop: [[1, 2]],
			mobile: [[1, 2]]
		}
	},
};

module.exports = {
	slotSettings: slotSettings
};