'use strict';

var $	   = require('jquery');
var layout = require('../ui/layout.js');

var screenSizeWatcherCallbacks = [];

var self = module.exports = {
	onChange: function (func) {
		screenSizeWatcherCallbacks.push(func);
	},

	start: function () {
		var timer, newSize, previousSize = layout.getScreenSize();
		$(window).off('resize.screenSizeWatcher').on('resize.screenSizeWatcher', function () {
			newSize = layout.getScreenSize();
			if (newSize !== previousSize) {
				previousSize = newSize;
				window.clearTimeout(timer);
				timer = window.setTimeout(function () {
					self.trigger();
				}, 500);
			}
		});
	},

	trigger: function () {
		var index, callback;
		for (index = 0; index < screenSizeWatcherCallbacks.length; index++) {
			callback = screenSizeWatcherCallbacks[index];
			if ($.isFunction(callback)) {
				callback();
			}
		}
	}
};